import * as React from 'react';
import I18nLink from '../../i18n/Link';
import Sentry from '../../components/error/sentry';
import { FormattedMessage } from 'react-intl';
import { withIntl } from '../../i18n';
import './error.scss';

class Auth0ErrorPage extends React.Component {
  private reportHandler: React.MouseEventHandler<any>

  constructor(props) {
    super(props)
    this.reportHandler = this.report.bind(this)
  }

  report() {
    const error: Error = new Error("An error happened during authentication with Auth0")
    Sentry.captureException(error)
    Sentry.showReportDialog()
  }

  render() {
    return (
      <div className="mdc-layout-grid cm-error">
        <div className="mdc-layout-grid__inner">
          <div className="mdc-layout-grid__cell--span-6 mdc-layout-grid__cell--align-top">
            <I18nLink to="/" id="cm-logo">
              <span />
            </I18nLink>
            <h1 className="mdc-typography--headline1">
              <FormattedMessage id="error.title" defaultMessage="Es ist ein Fehler aufgetreten" />
            </h1>
            <p className="mdc-typography--subtitle1">
              <FormattedMessage
                id="error.subtitle"
                defaultMessage="Bitte entschuldigen Sie die Unanehmlichkeiten. Wir wurden soeben über den Fehler informiert. Zusätzlich wäre es eine große Hilfe, wenn Sie kurz beschreiben, was passiert ist."
              />
            </p>
            <br />
            <a onClick={this.reportHandler} className="mdc-button mdc-button--raised" id="cm-feedback-cta">
              <FormattedMessage id="error.cta" defaultMessage="Beschreibung senden" />
            </a>
          </div>
          <div className="mdc-layout-grid__cell--span-4 mdc-layout-grid__cell--align-top">
            <div id="cm-tractor-flat-tire-image" />
          </div>
        </div>
      </div>
    )
  }
}

export default withIntl(Auth0ErrorPage)
